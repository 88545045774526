<template>
	<div style="width: 100%; height: 100%">
		<div class="pageContainer" v-if="mainLoginPage">
			<div class="login_img">
				<img src="../../assets/img/login/loginPage_img1.png" />
			</div>
			<div class="logo">
				<img src="https://resources.xbov.cn/img/hxznggfwpt/logo.png" />
				<span class="logoText">
					鸿星智能
					<span class="content">（湖南）</span>
				</span>
			</div>
			<div class="login_text">
				<h1>行业自律 政府监管 社会监督</h1>
				<p>智广宝-鸿星智能广告服务平台为广告主、广告设计者、广告经营者、广告媒介主、广告受众群体、提供智能化广告检测服务.</p>
				<div class="btn">
					<a-button shape="round" @click="illegalAdSubmit">广告上刊检测</a-button>
					<a-button shape="round" @click="illegalAdQuery">检测结果查询</a-button>
				</div>
			</div>
			<div class="login_qrCode">
				<img src="../../assets/img/login/qrCode.png" />
				<div>1、打开手机微信扫一扫</div>
				<div>2、微信搜索“智广宝”小程序</div>
			</div>
			<div class="login_footer">
				<!-- <p>开放平台 | 关于我们 | 联系我们</p> -->
				<p class="footLinks">
					友情链接：
					<a href="http://www.xbov.cn" target="_blank">星华播控</a>
					<a href="http://www.admot.cn" target="_blank">鸿星智能</a>
				</p>
				<p>法律支持：湖南省广告协会法律委员会</p>
				<p>
					© Copyright 2015 - {{ currentYear }} 长沙鸿星智能科技有限公司 版权所有 |
					<a href="https://beian.miit.gov.cn" target="_blank" style="color: white;">湘ICP备2020023992号-2</a>
					|
					<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001551" target="_blank" style="color: white;">
						<img src="https://resources.xbov.cn/img/record.png" style=" width: 15px; height: 15px" />
						湘公网安备 43019002001551号
					</a>
				</p>
			</div>
			<div class="loginForm">
				<LoginForm />
			</div>
		</div>
		<JxLogin v-if="jxLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<BcLogin v-if="bcLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<GzLogin v-if="gzLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<FjLogin v-if="fjLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<AhLogin v-if="ahLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<QhLogin v-if="qhLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<HbLogin v-if="hbLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<ScLogin v-if="scLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<JsLogin v-if="jsLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<ZjLogin v-if="zjLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<ShLogin v-if="shLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<SdLogin v-if="sdLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<SxLogin v-if="sxLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<SxsLogin v-if="sxsLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<BjLogin v-if="bjLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<TjLogin v-if="tjLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<HnLogin v-if="hnLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<GdLogin v-if="gdLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<GxLogin v-if="gxLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<CqLogin v-if="cqLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<GsLogin v-if="gsLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<NxLogin v-if="nxLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<LnLogin v-if="lnLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<YnLogin v-if="ynLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<JlLogin v-if="jlLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<HiLogin v-if="hiLoginPage" :illegalAdSubmit="illegalAdSubmit" :illegalAdQuery="illegalAdQuery" />
		<IllegalAdSubModal :illegalAdSubModalVisible="illegalAdSubModalVisible" :closeIllegalAdSubModal="closeIllegalAdSubModal" />
		<IllegalAdResultModal :illegalAdResultVisible="illegalAdResultVisible" :closeIllegalAdResultModal="closeIllegalAdResultModal" />
	</div>
</template>

<script>
import LoginForm from './components/LoginForm';
import IllegalAdSubModal from './components/IllegalAdSubModal';
import IllegalAdResultModal from './components/IllegalAdResultModal';
import BcLogin from './customLogin/BcLogin';
import GzLogin from './customLogin/GzLogin.vue';
import FjLogin from './customLogin/FjLogin.vue';
import JxLogin from './customLogin/JxLogin.vue';
import AhLogin from './customLogin/AhLogin.vue';
import QhLogin from './customLogin/QhLogin.vue';
import HbLogin from './customLogin/HbLogin.vue';
import ScLogin from './customLogin/ScLogin.vue';
import JsLogin from './customLogin/JsLogin.vue';
import ZjLogin from './customLogin/ZjLogin.vue';
import ShLogin from './customLogin/ShLogin.vue';
import SdLogin from './customLogin/SdLogin.vue';
import SxLogin from './customLogin/SxLogin.vue';
import SxsLogin from './customLogin/SxsLogin.vue';
import BjLogin from './customLogin/BjLogin.vue';
import TjLogin from './customLogin/TjLogin.vue';
import HnLogin from './customLogin/HnLogin.vue';
import GdLogin from './customLogin/GdLogin.vue';
import GxLogin from './customLogin/GxLogin.vue';
import CqLogin from './customLogin/CqLogin.vue';
import GsLogin from './customLogin/GsLogin.vue';
import NxLogin from './customLogin/NxLogin.vue';
import LnLogin from './customLogin/LnLogin.vue';
import YnLogin from './customLogin/YnLogin.vue';
import JlLogin from './customLogin/JlLogin.vue';
import HiLogin from './customLogin/HiLogin.vue';
export default {
	components: {
		LoginForm,
		IllegalAdResultModal,
		IllegalAdSubModal,
		BcLogin,
		GzLogin,
		FjLogin,
		JxLogin,
		AhLogin,
		QhLogin,
		HbLogin,
		ScLogin,
		JsLogin,
		ZjLogin,
		ShLogin,
		SdLogin,
		SxLogin,
		SxsLogin,
		BjLogin,
		TjLogin,
		HnLogin,
		GdLogin,
		GxLogin,
		CqLogin,
		GsLogin,
		NxLogin,
		LnLogin,
		YnLogin,
		JlLogin,
		HiLogin,
	},
	data() {
		return {
			illegalAdSubModalVisible: false,
			illegalAdResultVisible: false,
			currentYear: '',
			mainLoginPage: false,
			bcLoginPage: false,
			gzLoginPage: false,
			jxLoginPage: false,
			fjLoginPage: false,
			ahLoginPage: false,
			qhLoginPage: false,
			hbLoginPage: false,
			scLoginPage: false,
			lnLoginPage: false,
			nxLoginPage: false,
			gsLoginPage: false,
			cqLoginPage: false,
			gxLoginPage: false,
			gdLoginPage: false,
			hnLoginPage: false,
			tjLoginPage: false,
			bjLoginPage: false,
			sxsLoginPage: false,
			sxLoginPage: false,
			sdLoginPage: false,
			shLoginPage: false,
			zjLoginPage: false,
			jsLoginPage: false,
			ynLoginPage: false,
			jlLoginPage: false,
			hiLoginPage: false,
		};
	},
	created() {
		this.currentYear = this.$moment().format('YYYY');
		let domain = document.domain;
		let params = {
			domainName: domain,
		};
		const _this = this;
		this.$http
			.post('/domaincustom/queryList', params)
			.then(res => {
				if (res.rescode == 200) {
					if (res.data.dataList.length == 0) {
						_this.mainLoginPage = true;
						return;
					}
					if (res.data.dataList.length != 0) {
						if (domain == 'bc.zbov.cn') {
							this.bcLoginPage = true;
							var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
							link.type = 'image/x-icon';
							link.rel = 'shortcut icon';
							link.href = 'https://resources.xbov.cn/img/hxznggfwpt/bc_ico.ico';
							document.getElementsByTagName('head')[0].appendChild(link);
							document.title = res.data.dataList[0].title;
                        } else if (domain == 'jl.adtoo.cn') {
                            this.jlLoginPage = true;
                            document.title = res.data.dataList[0].title;
                        } else if (domain == 'hi.adtoo.cn') {
                            this.hiLoginPage = true;
                            document.title = res.data.dataList[0].title;
                        } else if (domain == 'yn.adtoo.cn') {
							this.ynLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'ln.adtoo.cn') {
							this.lnLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'nx.adtoo.cn') {
							this.nxLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'gs.adtoo.cn') {
							this.gsLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'cq.adtoo.cn') {
							this.cqLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'gx.adtoo.cn') {
							this.gxLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'gd.adtoo.cn') {
							this.gdLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'hn.adtoo.cn') {
							this.hnLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'tj.adtoo.cn') {
							this.tjLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'bj.adtoo.cn') {
							this.bjLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'sxs.adtoo.cn') {
							this.sxsLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'sx.adtoo.cn') {
							this.sxLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'sd.adtoo.cn') {
							this.sdLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'sh.adtoo.cn') {
							this.shLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'zj.adtoo.cn') {
							this.zjLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'js.adtoo.cn') {
							this.jsLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'sc.adtoo.cn') {
							this.scLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'hb.adtoo.cn') {
							this.hbLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'ah.adtoo.cn') {
							this.ahLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'qh.adtoo.cn') {
							this.qhLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'jx.adtoo.cn') {
							this.jxLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'gz.adtoo.cn') {
							this.gzLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'fj.adtoo.cn') {
							this.fjLoginPage = true;
							document.title = res.data.dataList[0].title;
						} else if (domain == 'adtoo.admot.cn') {
							_this.gzLoginPage = false;
							_this.bcLoginPage = false;
							_this.fjLoginPage = false;
							_this.mainLoginPage = true;
						} else {
							_this.mainLoginPage = true;
						}
						_this.$store.dispatch('saveDomainInfo', res.data.dataList[0]);
					}
				} else {
					_this.mainLoginPage = true;
				}
			})
			.catch(err => {
				_this.mainLoginPage = true;
			});
	},
	methods: {
		closeIllegalAdResultModal() {
			this.illegalAdResultVisible = false;
		},
		illegalAdQuery() {
			this.illegalAdResultVisible = true;
		},
		closeIllegalAdSubModal() {
			this.illegalAdSubModalVisible = false;
		},
		illegalAdSubmit() {
			this.illegalAdSubModalVisible = true;
		},
	},
};
</script>

<style lang="less" scoped>
.pageContainer {
	background: url('https://resources.xbov.cn/img/bg_login2.png') no-repeat;
	background-size: 100% 100%;
	position: relative;
	.login_qrCode {
		position: absolute;
		right: 60px;
		bottom: 40px;
		img {
			width: 100px;
			height: 100px;
		}
		div {
			font-size: 18px;
			color: #ffffff;
			line-height: 26px;
		}
	}
	.login_footer {
		position: absolute;
		bottom: 0;
		left: -30px;
		text-align: center;
		font-size: 20px;
		color: #ffffff;
		line-height: 30px;
		transform: translateX(40%);
		.text {
			margin-right: 20px;
		}
		.footLinks {
			a {
				color: #ffffff;
				margin-right: 8px;
			}
			a:hover {
				color: #fadb14;
			}
		}
	}
	.btn {
		margin-top: 26px;
		.ant-btn {
			margin-right: 40px;
			color: #2d7bf8;
			font-weight: 600;
			font-size: 20px;
		}
	}
	.login_text {
		color: #ffffff;
		// line-height: 30px;
		font-size: 22px;
		width: 730px;
		position: absolute;
		bottom: 100px;
		left: 180px;
		h1 {
			font-size: 52px;
			font-family: 'PangMenZhengDao-Cu';
			padding-bottom: 20px;
			color: #ffffff;
		}
		p {
			text-indent: 2em;
			line-height: 30px;
		}
	}
	.login_img {
		width: 622px;
		height: 577px;
		position: absolute;
		left: 240px;
		top: 64px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.logo {
		width: 30px;
		height: 30px;
		position: absolute;
		left: 70px;
		top: 30px;
		img {
			width: 100%;
			height: 100%;
		}
		.logoText {
			width: 220px;
			height: 30px;
			font-size: 25px;
			font-weight: 550;
			color: #ffffff;
			position: absolute;
			top: 3px;
			left: 50px;
			.content {
				font-size: 27px;
			}
		}
	}
}
</style>
