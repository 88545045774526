<template>
    <a-modal :visible="illegalAdResultVisible" :maskClosable="false" title="广告合规检测结果查询" :footer="false" @cancel="closeModal">
        <a-spin :spinning="loading">
            <a-input-group compact>
                <a-input v-model="flowId" class="codeInput" placeholder="请输入编号"></a-input>
                <a-button type="primary" class="queryBtn" @click="query">查询</a-button>
            </a-input-group>
            <template v-if="infoData && infoData.length != 0">
                <a-row class="titleRow">
                    <span class="longString"></span>
                    检测内容
                </a-row>
                <a-row type="flex" justify="start" :gutter="8">
                    <a-col :span="4" class="imgBox" v-for="(item, index) in infoData" :key="index" @click="preview(item)">
                        <img :src="host + item.coverImgUrl" />
                        <a-icon class="playIcon" type="play-circle" v-if="item.fileType == 2" />
                    </a-col>
                </a-row>
            </template>
            <a-row class="titleRow">
                <span class="longString"></span>
                检测结果
            </a-row>
            <a-textarea class="textarea" readOnly v-model="resultData" placeholder="暂无结果" :rows="4"></a-textarea>
            <a-row class="titleRow">
                <span class="longString"></span>
                备注信息
            </a-row>
            <a-textarea class="textarea" readOnly v-model="remark" placeholder="暂无备注" :rows="4"></a-textarea>
        </a-spin>
        <a-row class="flex justify-center">
            <a-button class="closeBtn" shape="round" type="primary" @click="closeModal">关闭</a-button>
        </a-row>
        <Vviewer ref="viewer"></Vviewer>
        <MediaViewer :visible="mediaVisible" :mediaSrc="mediaSrc" :mediaType="mediaType" :closeMediaModal="closeMediaModal" />
    </a-modal>
</template>

<script>
import Vviewer from "../../../components/Vviewer";
import MediaViewer from "../../../components/MediaViewer";
export default {
    props: ["illegalAdResultVisible", "closeIllegalAdResultModal"],
    components: {
        Vviewer,
        MediaViewer,
    },
    data() {
        return {
            flowId: undefined,
            resultData: undefined,
            loading: false,
            infoData: null,
            remark: undefined,
            host: null,
            mediaVisible: false,
            mediaSrc: null,
            mediaType: null,
        };
    },
    created() {
        this.host = process.env.VUE_APP_RESOURCES_HOST;
    },
    methods: {
        closeMediaModal() {
            this.mediaVisible = false;
            this.mediaSrc = null;
            this.mediaType = null;
        },
        preview(data) {
            if (data.fileType == 1) {
                this.$refs.viewer.show([
                    {
                        thumbnail: this.host + data.coverImgUrl,
                        source: this.host + data.fileUrl,
                    },
                ]);
            } else if (data.fileType == 2) {
                this.mediaSrc = this.host + data.fileUrl;
                this.mediaVisible = true;
                this.mediaType = 2;
            }
        },
        query() {
            if (!this.flowId || this.flowId == "") {
                this.$message.warning("请输入编号！");
                return;
            }
            if (!this.$CommonUtil.numTest(this.flowId)) {
                this.$message.warning("编号错误（纯数字标号）!");
                return;
            }
            const _this = this;
            _this.loading = true;
            this.$http
                .get("/reportinfo/query?flowId=" + this.flowId)
                .then((res) => {
                    _this.loading = false;
                    if (res.rescode == 200) {
                        _this.infoData = JSON.parse(res.data.content);
                        _this.remark = res.data.remark;
                        if (res.data.result) {
                            _this.resultData = res.data.result;
                        } else {
                            _this.resultData = "您提交的问题正在处理中···";
                        }
                    } else {
                        _this.$Notification["error"]({
                            message: "请求失败！",
                            description: res.msg,
                        });
                    }
                })
                .catch((err) => {
                    _this.loading = false;
                    console.log("查询失败", err);
                });
        },
        closeModal() {
            this.flowId = undefined;
            this.resultData = undefined;
            this.infoData = null;
            this.remark = undefined;
            this.closeIllegalAdResultModal();
        },
    },
};
</script>

<style lang="less" scoped>
.imgBox {
    height: 60px;
    position: relative;
    .playIcon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        color: #fff;
    }
    img {
        width: 100%;
        height: 100%;
    }
}
.titleRow {
    font-size: 18px;
    position: relative;
    margin: 8px 0;
    // margin-left: 16px;
    .longString {
        width: 3px;
        height: 25px;
        background-color: #2d7bf8;
        position: absolute;
        // top: -3px;
        left: -10px;
    }
}
.closeBtn {
    width: 190px;
}
.codeInput {
    width: 80%;
}
.queryBtn {
    width: 20%;
}
.textarea {
    margin: 0 0 16px 0;
    background: #e9eff8;
}
</style>
