<template>
	<div class="pageContainer">
		<div class="logo">
			<img src="https://resources.xbov.cn/img/hxznggfwpt/logo.png" />
			<span class="logoText">
				鸿星智能
				<span class="content">（安徽）</span>
			</span>
		</div>
		<div class="loginForm">
			<div class="name">安徽省广告协会智能服务平台</div>
			<div class="form">
				<a-tabs default-active-key="1" size="large" :tabBarGutter="50" @change="callback">
					<a-tab-pane key="1" tab="快捷登录">
						<a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="userPhone" placeholder="请输入您的手机号">
							<a-icon slot="prefix" type="user" />
						</a-input>
						<a-input-group compact>
							<a-input class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" v-model="userPhoneSms" placeholder="请输入您的验证码">
								<a-icon slot="prefix" type="lock" />
							</a-input>
							<a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? '秒' : '' }}</a-button>
						</a-input-group>
					</a-tab-pane>
					<a-tab-pane key="2" tab="手机号密码登录">
						<a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="loginName" placeholder="请输入您的手机号">
							<a-icon slot="prefix" type="user" />
						</a-input>
						<a-input-password class="formInput" ref="userPhoneInput" v-model="loginPass" placeholder="请输入您的密码">
							<a-icon slot="prefix" type="lock" />
						</a-input-password>
						<a-input-group compact v-if="codeFlag" class="code">
							<a-input class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" v-model="userPhoneSms" placeholder="请输入您的验证码">
								<a-icon slot="prefix" type="lock" />
							</a-input>
							<a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? '秒' : '' }}</a-button>
						</a-input-group>
					</a-tab-pane>
				</a-tabs>
				<a-button block class="submitBtn" type="primary" @click="login()">立即登录</a-button>
				<div class="loginForm_footer">
					<a @click="register">立即注册</a>
					<a @click="forgetPassword()">忘记密码</a>
				</div>
			</div>
		</div>
		<div class="login_text">
			<h1>行业自律 政府监管 社会监督</h1>
			<p>智广宝-鸿星智能广告服务平台为广告主、广告设计者、广告经营者、广告媒介主、广告受众群体、提供智能化广告检测服务.</p>
			<div class="btn">
				<a-button type="primary" shape="round" @click="bcIllegalAdSubmit">广告合规检测</a-button>
				<a-button type="primary" shape="round" @click="bcIllegalAdQuery">检测结果查询</a-button>
				<a-button type="primary" shape="round" @click="showMiniAppCode">智能服务小程序</a-button>
			</div>
		</div>
		<div class="login_footer">
			<p class="footLinks">
				友情链接：
				<a href="http://www.xbov.cn" target="_blank">星华播控</a>
				<a href="http://www.admot.cn" target="_blank">鸿星智能</a>
			</p>
			<p>法律支持：安徽省广告协会</p>
			<p>
				© Copyright 2015 - {{ currentYear }} 长沙鸿星智能科技有限公司 版权所有 |
				<a href="https://beian.miit.gov.cn" target="_blank" style="color:  #000;">湘ICP备2020023992号-2</a>
				|
				<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001551" target="_blank" style="color:  #000;">
					<img src="https://resources.xbov.cn/img/record.png" style=" width: 15px; height: 15px" />
					湘公网安备 43019002001551号
				</a>
			</p>
		</div>
		<a-modal :visible="miniAppQr" :footer="null" class="qrcodeModal" @cancel="closeQrcodeModal">
			<div class="qrCodeBox flex justify-center">
				<img width="258" height="258" src="https://resources.xbov.cn/img/hxznggfwpt/adtoo_minApp.jpg" />
			</div>
			<div style="font-size: 20px; text-align: center">打开微信扫一扫</div>
		</a-modal>
	</div>
</template>
<script>
export default {
	props: ['illegalAdSubmit', 'illegalAdQuery'],
	data() {
		return {
			domainInfo: {},
			loginType: 1,
			userPhone: null,
			userPhoneSms: null,
			loginName: null,
			loginPass: null,
			tabsKey: 1,
			smsCount: '获取验证码',
			getSmscodeFlag: false,
			codeFlag: false,
			currentYear: '',
			miniAppQr: false,
		};
	},
	created() {
		this.domainInfo = this.$store.getters.domainInfo;
		this.currentYear = this.$moment().format('YYYY');
	},
	methods: {
		closeQrcodeModal() {
			this.miniAppQr = false;
		},
		showMiniAppCode() {
			this.miniAppQr = true;
		},
		getSmsCode() {
			const _this = this;
			if (this.tabsKey == 1) {
				phone = this.userPhone;
			} else {
				phone = this.loginName;
			}
			if (!phone || phone == '') {
				_this.$message.warning('请先输入手机号');
				return;
			}
			if (!this.$CommonUtil.phoneTest(phone)) {
				this.$message.warning('手机号格式错误!');
				return;
			}
			_this.$http.get('/login/smsSendCode?phone=' + (this.tabsKey == 1 ? _this.userPhone : _this.loginName) + '&type=2').then(res => {
				if (res.rescode == 200) {
					_this.getSmscodeFlag = true;
					_this.smsCount = 60;
					let timer = setInterval(() => {
						_this.smsCount -= 1;
						if (_this.smsCount == 0) {
							clearInterval(timer);
							_this.smsCount = '获取验证码';
							_this.getSmscodeFlag = false;
						}
					}, 1000);
				} else if (res.rescode == 201 && res.msg == '用户名输入错误！') {
					_this.$Notification['error']({
						message: '发送失败！',
						description: '该用户名不存在',
					});
				} else {
					_this.$Notification['error']({
						message: '请求错误！',
						description: res.msg,
					});
				}
			});
		},
		callback(key) {
			this.tabsKey = key;
		},
		forgetPassword() {
			this.$router.push({
				name: 'ForgetPassword',
				query: {},
			});
		},
		login() {
			let params = {};
			let url = '';
			const _this = this;
			let phone = '';
			if (this.tabsKey == 1) {
				phone = this.userPhone;
			} else {
				phone = this.loginName;
			}
			if (!phone || phone == '') {
				_this.$message.warning('请先输入手机号');
				return;
			}
			if (!this.$CommonUtil.phoneTest(phone)) {
				this.$message.warning('手机号格式错误!');
				return;
			}
			if (this.tabsKey == 1) {
				if (!this.userPhoneSms || this.userPhoneSms == '') {
					_this.$message.warning('请先输入验证码');
					return;
				}
				if (this.userPhoneSms.length != 6) {
					_this.$message.warning('请先输入6位数的验证码');
					return;
				}
				if (!this.$CommonUtil.numTest(this.userPhoneSms)) {
					this.$message.warning('验证码错误!');
					return;
				}
				params.loginName = this.userPhone;
				params.smsCode = this.userPhoneSms;
				url = '/login/loginByPhone';
			} else {
				params.loginName = this.loginName;
				params.loginPass = this.loginPass;
				params.phoneCode = this.userPhoneSms;
				url = '/login/login';
			}
			this.$http.post(url, params).then(res => {
				if (res.rescode == 200) {
					_this.$store.dispatch('saveUserInfo', res.data.userInfo);
					_this.$store.dispatch('saveToken', res.data.token);

					if (sessionStorage.getItem('routerCome')) {
						window.location.href = sessionStorage.getItem('routerCome');
						sessionStorage.removeItem('routerCome');
					} else {
						_this.$router.push({ path: '/home' });
					}
				} else if (res.rescode == 206) {
					this.codeFlag = true;
				}
			});
		},
		register() {
			this.$router.push({
				name: 'Register',
				query: {},
			});
		},
		bcIllegalAdSubmit() {
			this.illegalAdSubmit();
		},
		bcIllegalAdQuery() {
			this.illegalAdQuery();
		},
	},
};
</script>
<style lang="less" scoped>
.pageContainer {
	background: url('https://resources.xbov.cn/img/hxznggfwpt/gz_adtoo_cn_1920_1080.png') no-repeat !important;
	background-size: 100% 100% !important;
	position: relative !important;
	.logo {
		width: 30px;
		height: 30px;
		position: absolute;
		left: 70px;
		top: 30px;
		img {
			width: 100%;
			height: 100%;
		}
		.logoText {
			width: 220px;
			height: 30px;
			font-size: 25px;
			font-weight: 550;
			color: #ffffff;
			position: absolute;
			top: 3px;
			left: 50px;
			.content {
				font-size: 27px;
			}
		}
	}
	.loginForm {
		width: 720px;
		opacity: 1;
		border-radius: 30px;
		position: absolute;
		right: 20px;
		top: 230px;
		padding: 50px 50px;
		.name {
			font-size: 30px;
			font-weight: bold;
			color: #000;
			letter-spacing: 4px;
		}
		.form {
			width: 400px;
			margin-top: 8px;
			/deep/ .formInput {
				input {
					border: none;
					border-radius: 0;
					border-bottom: 1px solid #d9d9d9;
				}
			}
			/deep/ .userPhoneSmsInput {
				width: 68%;
			}
			.loginForm_footer {
				margin-top: 20px;
				color: #2d7bf8;
				display: flex;
				justify-content: space-between;
				font-size: 16px;
				font-weight: 600;
			}
			.submitBtn {
				margin-top: 30px;
			}
			.userPhoneInput {
				margin-bottom: 16px;
			}
			.subtitle {
				font-size: 24px;
				font-weight: 500;
				line-height: 46px;
				color: #2d7bf8;
				margin-bottom: 20px;
			}
			.title {
				font-size: 45px;
				color: #2d7bf8;
				font-weight: 600;
				line-height: 63px;
			}
			.getCode {
				width: 32%;
				border-radius: 3px;
			}
			.code {
				margin-top: 10px;
			}
		}
		// .tabTag {
		//     margin-top: 20px;
		//     a {
		//         padding: 4px;
		//         font-size: 18px;
		//         color: #000;
		//     }
		//     a.active {
		//         border-bottom: 2px solid #40a9ff;
		//         color: #40a9ff;
		//     }
		//     .quickLogin {
		//         margin-right: 200px;
		//     }
		// }
		/deep/ .ant-tabs-bar {
			border-bottom: none;
		}
	}
	.login_footer {
		position: absolute;
		bottom: 0;
		text-align: center;
		font-size: 16px;
		color: #000;
		line-height: 30px;
		width: 100%;
		.text {
			margin-right: 20px;
		}
		.footLinks {
			a {
				color: #000;
				margin-right: 8px;
			}
			a:hover {
				color: #fadb14;
			}
		}
	}
	.login_text {
		color: #ffffff;
		// line-height: 30px;
		font-size: 22px;
		width: 550px;
		position: absolute;
		top: 120px;
		left: 240px;
		h1 {
			font-size: 42px;
			font-family: 'PangMenZhengDao-Cu';
			padding-bottom: 20px;
			color: #ffffff;
		}
		p {
			text-indent: 2em;
			line-height: 26px;
			font-size: 20px;
		}
		.btn {
			margin-top: 30px;
			.ant-btn {
				margin-right: 16px;
			}
		}
	}
}
@media screen and (max-width: 1910px) {
	.pageContainer {
		background: url('https://resources.xbov.cn/img/hxznggfwpt/gz_adtoo_cn_1600_900.png') no-repeat !important;
		.loginForm {
			right: -50px !important;
		}
	}
}
@media screen and (max-width: 1590px) {
	.pageContainer {
		background: url('https://resources.xbov.cn/img/hxznggfwpt/gz_adtoo_cn_1440_900.png') no-repeat !important;
		.loginForm {
			right: -50px !important;
		}
	}
}
@media screen and (max-width: 1430px) {
	.pageContainer {
		background: url('https://resources.xbov.cn/img/hxznggfwpt/gz_adtoo_cn_1366_768.png') no-repeat !important;
		.loginForm {
			right: -100px !important;
		}
	}
}
</style>
