<template>
	<a-modal :visible="visible" :footer="false" class="mediaViewer" :width="mediaType == 2 ? 1200 : 520" @cancel="closeMediaPreviewModal">
		<a-icon type="close-circle" theme="twoTone" slot="closeIcon"></a-icon>
		<video ref="video" :src="mediaSrc" autoplay controls></video>
	</a-modal>
</template>

<script>
export default {
	props: ['visible', 'mediaSrc', 'mediaType', 'closeMediaModal'],
	methods: {
		closeMediaPreviewModal() {
			this.$refs.video.pause();
			this.closeMediaModal();
		},
	},
};
</script>

<style lang="less" scoped>
.mediaViewer {
	background: #000;
	/deep/.ant-modal {
		height: 700px;
		.ant-modal-content {
			height: 100%;
			.ant-modal-body {
				font-size: 0;
				padding: 0;
				height: 100%;
				video {
					overflow: hidden;
					width: 100%;
					height: 100%;
				}
				iframe {
					width: 100%;
					height: 100%;
					border: none;
				}
			}
		}
	}
}
</style>
