<template>
    <a-modal class="illegalAdSubModal" title="内容上传" :maskClosable="false" okText="提交" cancelText="关闭" :visible="illegalAdSubModalVisible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
        <a-upload-dragger :fileList="fileList" name="file" :multiple="true" :before-upload="beforeUpload" :action="resourcesHost" @change="handleChange">
            <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
                点击或者拖动文件上传
            </p>
            <p class="ant-upload-hint">
                广告合规检测仅面向已上刊的广告画面内容
            </p>
            <p class="ant-upload-hint">
                仅支持图片或视频，最多上传5个文件
            </p>
        </a-upload-dragger>
        <a-divider />
        <div class="title">备注说明</div>
        <a-textarea v-model="remark" :rows="4"></a-textarea>
        <a-divider />
        <div class="title">联系信息</div>
        <a-input class="formInput" v-model="userPhone" placeholder="请输入您的手机号码"></a-input>
        <a-input-group compact>
            <a-input v-model="smsCode" class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" placeholder="请输入您的验证码"> </a-input>
            <a-button type="primary" class="getSmscodeBtn" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }} {{ getSmscodeFlag ? "秒" : "" }}</a-button>
        </a-input-group>
        <span>*提交个人信息将会受到严格保护，联系信息仅用于情况确认</span>

        <a-modal class="successModal" :maskClosable="false" :footer="false" :visible="successVisible" @cancel="successCancel" width="510px">
            <p class="title">您的回执编号：{{ flowId }}</p>
            <p class="content">您的广告内容已提交系统检测，将在48小时内出结果，请保存好回执编号。</p>
            <span class="buttonBox">
                <a-button type="primary" class="button" v-clipboard:copy="flowId" v-clipboard:success="onCopy" v-clipboard:error="onError">复制回执编号</a-button>
            </span>
        </a-modal>
    </a-modal>
</template>

<script>
export default {
    props: ["illegalAdSubModalVisible", "closeIllegalAdSubModal"],
    data() {
        return {
            confirmLoading: false,
            remark: undefined,
            userPhone: undefined,
            smsCode: undefined,
            smsCount: "获取验证码",
            fileList: [],
            resourcesHost: "",
            getSmscodeFlag: false,
            timer: null,
            successVisible: false,
            flowId: "",
        };
    },
    created() {
        this.resourcesHost = process.env.VUE_APP_RESOURCES_API_HOST;
    },
    methods: {
        // 复制成功
        onCopy(e) {
            this.$message.success("复制成功");
        },
        // 复制失败
        onError(e) {
            // alert("失败");
            this.$message.warning("复制失败");
        },
        getSmsCode() {
            if (!this.userPhone || this.userPhone == "") {
                this.$message.warning("请输入手机号");
                return;
            }
            if (!this.$CommonUtil.phoneTest(this.userPhone)) {
                this.$message.warning("手机号格式错误!");
                return;
            }
            const _this = this;
            this.$http.get("/login/smsSendCode?phone=" + this.userPhone + "&type=2").then((res) => {
                _this.getSmscodeFlag = true;
                _this.smsCount = 60;
                _this.timer = setInterval(() => {
                    _this.smsCount -= 1;
                    if (_this.smsCount == 0) {
                        clearInterval(_this.timer);
                        _this.smsCount = "获取验证码";
                        _this.getSmscodeFlag = false;
                    }
                }, 1000);
            });
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const isJpgOrvideo = file.type === "image/jpeg" || file.type === "image/png" || file.type === "video/mp4";
                if (!isJpgOrvideo) {
                    this.$message.error("仅支持图片或视频！");
                    return reject(false);
                }
                let fileCount = this.fileList.length;
                if (fileCount == 5) {
                    this.$message.error("最多上传5个文件！");
                    return reject(false);
                }
                return resolve(true);
            });
        },
        handleChange({ file, fileList }) {
            this.fileList = fileList;
        },
        handleCancel() {
            const _this = this;
            _this.closeIllegalAdSubModal();
            _this.confirmLoading = false;
            _this.fileList = [];
            _this.remark = undefined;
            _this.userPhone = undefined;
            _this.smsCount = "获取验证码";
            _this.getSmscodeFlag = false;
            _this.smsCode = undefined;
            if (_this.timer) {
                clearInterval(_this.timer);
            }
        },
        handleOk() {
            if (this.fileList.length == 0) {
                this.$message.warning("请上传图片或视频！");
                return;
            }
            if (!this.userPhone || this.userPhone == "") {
                this.$message.warning("请输入您的手机号码！");
                return;
            }
            if (!this.$CommonUtil.phoneTest(this.userPhone)) {
                this.$message.warning("手机号格式错误!");
                return;
            }
            if (!this.smsCode || this.smsCode == "") {
                this.$message.warning("请输入手机验证码！");
                return;
            }
            if (this.smsCode.length != 6) {
                this.$message.warning("请先输入6位数的验证码");
                return;
            }
            if (!this.$CommonUtil.numTest(this.smsCode)) {
                this.$message.warning("验证码错误!");
                return;
            }
            let uploadFaild = false;
            this.fileList.map((item) => {
                if (item.status == "uploading") {
                    uploadFaild = 1; //正在上传
                } else if (item.status == "error") {
                    uploadFaild = 2; //上传失败
                }
            });
            if (uploadFaild == 1) {
                this.$message.warning("文件正在上传中，请等待文件上传完毕！");
                return;
            }
            if (uploadFaild == 2) {
                this.$message.warning("文件上传失败，请重新上传！");
                return;
            }
            this.confirmLoading = true;
            const _this = this;
            let params = {
                phone: this.userPhone,
                remark: this.remark,
                smsCode: this.smsCode,
            };
            let voList = [];
            this.fileList.map((item) => {
                let obj = {
                    coverImgUrl: item.response.data.coverImgUrl,
                    fileType: item.type == "image/jpeg" || item.type == "image/png" ? 1 : 2,
                    fileUrl: item.response.data.filePath,
                };
                voList.push(obj);
            });
            params.voList = voList;
            this.$http
                .post("/reportinfo/add", params)
                .then((res) => {
                    _this.closeIllegalAdSubModal();
                    _this.confirmLoading = false;
                    _this.fileList = [];
                    _this.remark = undefined;
                    _this.userPhone = undefined;
                    _this.getSmscodeFlag = false;
                    _this.smsCount = "获取验证码";
                    _this.smsCode = undefined;
                    if (_this.timer) {
                        clearInterval(_this.timer);
                    }
                    _this.successVisible = true;
                    _this.flowId = res.data.flowId;
                })
                .catch((err) => {
                    console.log("提交违法广告失败", err);
                    this.confirmLoading = false;
                });
            // setTimeout(() => {

            // }, 2000);
        },
        successCancel() {
            this.successVisible = false;
        },
    },
};
</script>

<style lang="less" scoped>
.illegalAdSubModal {
    .title {
        margin: 8px 0;
    }
    /deep/ .userPhoneSmsInput {
        width: 80%;
    }
    .getSmscodeBtn {
        width: 20%;
    }
    .formInput {
        margin-bottom: 8px;
    }
}
.successModal {
    text-align: center;

    .title {
        font-size: 30px;
        color: #2d7bf8;
        text-align: center;
        margin-bottom: 8px;
    }
    .content {
        text-align: left;
        text-indent: 2em;
        font-size: 18px;
    }
    .button {
        margin-top: 20px;
        // margin-left: 170px;
    }
}
</style>
