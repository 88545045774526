<template>
	<div class="loginForm">
		<p class="title">欢迎登录</p>
		<p class="subtitle">智广宝-鸿星智能广告服务平台</p>
		<a-tabs default-active-key="1" :tabBarGutter="50" @change="callback">
			<a-tab-pane key="1" tab="快捷登录">
				<a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="userPhone" placeholder="请输入您的手机号">
					<a-icon slot="prefix" type="user" />
				</a-input>
				<a-input-group compact>
					<a-input class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" v-model="userPhoneSms" placeholder="请输入您的验证码" @pressEnter="login()">
						<a-icon slot="prefix" type="lock" />
					</a-input>
					<a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? '秒' : '' }}</a-button>
				</a-input-group>
			</a-tab-pane>
			<a-tab-pane key="2" tab="手机号密码登录">
				<a-input class="formInput userPhoneInput" ref="userPhoneInput" v-model="loginName" placeholder="请输入您的手机号">
					<a-icon slot="prefix" type="user" />
				</a-input>
				<a-input-password class="formInput" ref="userPhoneInput" v-model="loginPass" placeholder="请输入您的密码" @pressEnter="login()">
					<a-icon slot="prefix" type="lock" />
				</a-input-password>
				<a-input-group compact v-if="codeFlag" class="code">
					<a-input class="formInput userPhoneSmsInput" ref="userPhoneSmsInput" v-model="userPhoneSms" placeholder="请输入您的验证码" @pressEnter="login()">
						<a-icon slot="prefix" type="lock" />
					</a-input>
					<a-button type="primary" class="getCode" :disabled="getSmscodeFlag" @click="getSmsCode">{{ smsCount }}{{ getSmscodeFlag ? '秒' : '' }}</a-button>
				</a-input-group>
			</a-tab-pane>
		</a-tabs>
		<a-button block class="submitBtn" type="primary" @click="login()">立即登录</a-button>
		<div class="loginForm_footer">
			<a @click="register">立即注册</a>
			<a @click="forgetPassword()">忘记密码</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userPhone: null,
			userPhoneSms: null,
			loginName: null,
			loginPass: null,
			tabsKey: 1,
			smsCount: '获取验证码',
			getSmscodeFlag: false,
			codeFlag: false,
		};
	},
	methods: {
		getSmsCode() {
			const _this = this;
			let phone = '';
			if (this.tabsKey == 1) {
				phone = this.userPhone;
			} else {
				phone = this.loginName;
			}
			if (!phone || phone == '') {
				_this.$message.warning('请先输入手机号');
				return;
			}
			if (!this.$CommonUtil.phoneTest(phone)) {
				this.$message.warning('手机号格式错误!');
				return;
			}
			_this.$http.get('/login/smsSendCode?phone=' + (this.tabsKey == 1 ? _this.userPhone : _this.loginName) + '&type=2').then(res => {
				if (res.rescode == 200) {
					_this.getSmscodeFlag = true;
					_this.smsCount = 60;
					let timer = setInterval(() => {
						_this.smsCount -= 1;
						if (_this.smsCount == 0) {
							clearInterval(timer);
							_this.smsCount = '获取验证码';
							_this.getSmscodeFlag = false;
						}
					}, 1000);
				} else if (res.rescode == 201 && res.msg == '用户名输入错误！') {
					_this.$Notification['error']({
						message: '发送失败！',
						description: '该用户名不存在',
					});
				} else {
					_this.$Notification['error']({
						message: '请求错误！',
						description: res.msg,
					});
				}
			});
		},
		callback(key) {
			this.tabsKey = key;
		},
		forgetPassword() {
			this.$router.push({
				name: 'ForgetPassword',
				query: {},
			});
		},
		login() {
			let params = {};
			let url = '';
			const _this = this;
			let phone = '';
			if (this.tabsKey == 1) {
				phone = this.userPhone;
			} else {
				phone = this.loginName;
			}
			if (!phone || phone == '') {
				_this.$message.warning('请先输入手机号');
				return;
			}
			if (!this.$CommonUtil.phoneTest(phone)) {
				this.$message.warning('手机号格式错误!');
				return;
			}
			if (this.tabsKey == 1) {
				if (!this.userPhoneSms || this.userPhoneSms == '') {
					_this.$message.warning('请先输入验证码');
					return;
				}
				if (this.userPhoneSms.length != 6) {
					_this.$message.warning('请先输入6位数的验证码');
					return;
				}
				if (!this.$CommonUtil.numTest(this.userPhoneSms)) {
					this.$message.warning('验证码错误!');
					return;
				}
				params.loginName = this.userPhone;
				params.smsCode = this.userPhoneSms;
				url = '/login/loginByPhone';
			} else {
				params.loginName = this.loginName;
				params.loginPass = this.loginPass;
				params.phoneCode = this.userPhoneSms;
				url = '/login/login';
			}
			this.$http.post(url, params).then(res => {
				if (res.rescode == 200) {
					_this.$store.dispatch('saveUserInfo', res.data.userInfo);
					_this.$store.dispatch('saveToken', res.data.token);

					if (sessionStorage.getItem('routerCome')) {
						window.location.href = sessionStorage.getItem('routerCome');
						sessionStorage.removeItem('routerCome');
					} else {
						_this.$router.push({ path: '/home' });
					}
				} else if (res.rescode == 206) {
					_this.codeFlag = true;
				} else {
					_this.$Notification['error']({
						message: '请求失败！',
						description: res.msg,
					});
				}
			});
		},
		register() {
			this.$router.push({
				name: 'Register',
				query: {},
			});
		},
	},
};
</script>

<style lang="less" scoped>
.loginForm {
	width: 430px;
	background: #ffffff;
	box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);
	opacity: 1;
	border-radius: 30px;
	position: absolute;
	top: 260px;
	right: 300px;
	padding: 50px 50px;
	/deep/ .ant-tabs-bar {
		border-bottom: none;
	}
	/deep/ .formInput {
		input {
			border: none;
			border-radius: 0;
			border-bottom: 1px solid #d9d9d9;
		}
	}
	/deep/ .userPhoneSmsInput {
		width: 68%;
	}
	.loginForm_footer {
		margin-top: 20px;
		color: #2d7bf8;
		display: flex;
		justify-content: space-between;
		font-size: 16px;
		font-weight: 600;
	}
	.submitBtn {
		margin-top: 30px;
	}
	.userPhoneInput {
		margin-bottom: 16px;
	}
	.subtitle {
		font-size: 24px;
		font-weight: 500;
		line-height: 46px;
		color: #2d7bf8;
		margin-bottom: 20px;
	}
	.title {
		font-size: 45px;
		color: #2d7bf8;
		font-weight: 600;
		line-height: 63px;
	}
	.getCode {
		width: 32%;
		border-radius: 3px;
	}
	.code {
		margin-top: 10px;
	}
}
</style>
